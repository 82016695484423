import React from 'react'
import TextContainer from '../ui-components/containers/text-container'
import Button from '../ui-components/button'

const ContactForm = () => {
  return (
    <div className="mt-24 pb-12">
      <TextContainer>
        <div className="w-full flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 border-0 md:border-r-2 border-brand text-center mb-8 md:mb-0">
            <h2 className="text-3xl font-handwritten">Kontakt</h2>
            <p className="mt-8">+49 164 567 3256</p>
            <p>info@shineonofficial.de</p>
            <p className="mt-8">Projekt Shine On</p>
            <p>Manuel Schmidt</p>
            <p>Altenhöfener Str. 133</p>
            <p>44623 Herne</p>
          </div>
          <form
            name="email-form"
            className="flex flex-col w-full md:w-3/4 md:pl-8 pl-0"
            method="POST"
            data-netlify="true"
            action="/danke"
          >
            <input type="hidden" name="form-name" value="email-form" />

            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name..."
                />
              </div>
              <div className="md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  E-Mail
                </label>
                <input
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-Mail"
                />
              </div>
            </div>
            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                  htmlFor="content"
                >
                  Nachricht
                </label>
                <textarea
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                  id="content"
                  name="message"
                  placeholder="Nachricht..."
                  rows="5"
                />
              </div>
            </div>
            <button
              type="submit"
              className="flex justify-center items-center border-2 py-2 rounded lg text-brand shadow-md cursor-pointer border-brand hover:shadow-lg hover:text-black bg-brand text-black"
            >
              <h2 className="font-handwritten text-center">Senden</h2>
            </button>
          </form>
        </div>
      </TextContainer>
    </div>
  )
}

export default ContactForm
