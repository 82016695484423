import React from 'react'
import ContactForm from '../components/contact/contact-form'
import Layout from '../components/ui-components/containers/layout'
import SEO from '../components/seo/SEO'

const Kontakt = () => {
  return (
    <Layout>
      <SEO title={'Kontakt'} />
      <div className="mt-24">
        <ContactForm></ContactForm>
      </div>
    </Layout>
  )
}

export default Kontakt
